import { init, browserTracingIntegration, captureConsoleIntegration } from "@sentry/vue";

/**
 *
 * @param {import("vue-router").Router} router
 * @returns {import("vue").FunctionPlugin}
 */
export function createSentry(router) {
  return function (app) {
    if (import.meta.env.SSR) return;
    init({
      app,
      dsn: "https://98c681b8eacf44fd8f5c0caafbbbaa54@o1039846.ingest.sentry.io/6759035",
      integrations: [browserTracingIntegration({ router }), captureConsoleIntegration({ levels: ["error"] })],
      ignoreErrors: [
        "AppError",
        "Cookie Control failed to verify your settings",
        "via a navigation guard",
        "Network Error",
        "There was an error setting cookie",
      ],
      logErrors: true,
    });
  };
}
