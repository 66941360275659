import "./styles/main.css";
import { registerPlugins } from "@/plugins";
import App from "./App.vue";
import { createApp } from "vue";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import minMax from "dayjs/plugin/minMax";
import "dayjs/locale/fr";

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(minMax);

const app = createApp(App);

registerPlugins(app).then(() => app.mount("#app"));
