import { ofetch } from "ofetch";
import AppError from "@/utils/AppError";
import FormError from "@/utils/AppError";
import { defaultWindow } from "@vueuse/core";
import { stringify } from "qs";
import { hasInjectionContext, ref } from "vue";

/**
 * @returns {import("vue").Plugin}
 */
export function createOfetch(i18n) {
  const { sid } = useSettingsStore();
  const messageStore = useMessageStore();
  const authStore = useAuthStore();
  const _ofetch = ofetch.create({
    headers: { Sid: sid },
    credentials: "same-origin",
    async onRequest(conf) {
      if (authStore.auth) {
        conf.options.headers.set("Authorization", `Bearer ${authStore.auth.access_token}`);
      }
      if (conf.options.query) {
        const queryString = stringify(conf.options.query);
        if (queryString) {
          conf.request += (conf.request.toString().indexOf("?") === -1 ? "?" : "&") + queryString;
        }
        delete conf.options.query;
      }
    },
    async onRequestError({ error }) {
      messageStore.error(i18n.global.t("errors.server.notreachable"));
      return Promise.reject(error);
    },
    async onResponseError({ response }) {
      const status = response.status;
      const errorData = response._data.error || response._data;
      if (status === 401) {
        if (console && console.log) console.log("401 Unauthorized", errorData);
        useBrowserLocation().value.href = "/login";
      } else if (status === 400) {
        if (console && console.log) console.log("Bad request", errorData);
        if (errorData.name === "AppError") {
          messageStore.error(i18n.global.t(errorData.message, errorData.params));
        } else if (errorData.name !== "FormError") {
          messageStore.error(i18n.global.t("errors.server.validation"));
        }
      } else if (status === 500) {
        if (console && console.log) console.log("Technical error", errorData);
        messageStore.error(i18n.global.t("errors.server.technical"));
      } else if (status === 404) {
        if (console && console.log) console.log("Not found error", errorData);
        messageStore.error(i18n.global.t("errors.server.notFound"));
      } else {
        if (console && console.log) console.log("Unexpected error", status, errorData);
        messageStore.error(i18n.global.t("errors.server.unexpected"));
      }
      let returnedError = errorData;
      if (returnedError && !(returnedError instanceof Error)) {
        if (returnedError.name === "AppError") {
          returnedError = new AppError(returnedError.message, returnedError.params);
        } else if (returnedError.name === "FormError") {
          returnedError = new FormError(returnedError.message, returnedError.params);
        }
      }
      throw returnedError;
    },
  });
  if (!import.meta.env.SSR) {
    defaultWindow["ofetch"] = _ofetch;
  }
  _ofetch["install"] = function (app) {
    app.provide("ofetch", this);
  };
  // @ts-ignore
  return _ofetch;
}

/**
 * @param {(args: any[]) => Parameters<import("ofetch").ofetch>} optionsGenerator
 * @returns {FetchAPI<any>}
 */
export function createAPIHandler(optionsGenerator) {
  const _fetch = async (args) =>
    await ((hasInjectionContext() && inject("ofetch")) || defaultWindow["ofetch"])(...optionsGenerator(args));
  // @ts-ignore
  return Object.assign((...args) => _fetch(args), {
    ref: function (defaultValue) {
      const _ref = Object.assign(ref(defaultValue), {
        fetch(...args) {
          let _onFulFilled, _onRejection, _onFinally;
          // @ts-ignore
          _fetch(args)
            .then((result) => {
              _ref.value = result;
              if (_onFulFilled) _onFulFilled(result);
            })
            .catch((error) => {
              if (_onRejection) _onRejection(error);
            })
            .finally(() => {
              if (_onFinally) _onFinally();
            });
          return Object.assign(_ref, {
            then: (onFulFilled, onRejection) => {
              _onFulFilled = onFulFilled;
              if (onRejection) _onRejection = onRejection;
              return _ref;
            },
            finally: (onFinally) => {
              _onFinally = onFinally;
              return _ref;
            },
            catch: (onRejection) => {
              _onRejection = onRejection;
              return _ref;
            },
          });
        },
      });
      return _ref;
    },
    refs: function (defaultValue) {
      const _refs = Object.assign(Object.fromEntries(Object.entries(defaultValue).map(([k, v]) => [k, ref(v)])), {
        fetch(...args) {
          let _onFulFilled, _onRejection, _onFinally;
          // @ts-ignore
          _fetch(args)
            .then((result) => {
              for (const key in result) {
                if (_refs[key]) _refs[key].value = result[key];
              }
              if (_onFulFilled) _onFulFilled(result);
            })
            .catch((error) => {
              if (_onRejection) _onRejection(error);
            })
            .finally(() => {
              if (_onFinally) _onFinally();
            });
          return Object.assign(_refs, {
            then: (onFulFilled, onRejection) => {
              _onFulFilled = onFulFilled;
              if (onRejection) _onRejection = onRejection;
              return _refs;
            },
            finally: (onFinally) => {
              _onFinally = onFinally;
              return _refs;
            },
            catch: (onRejection) => {
              _onRejection = onRejection;
              return _refs;
            },
          });
        },
      });
      return _refs;
    },
  });
}

/**
 *
 * @type {typeof ofetch.raw<any,any>}
 */
export const useOFetchRaw = async function (url, options) {
  return await ((hasInjectionContext() && inject("ofetch")) || defaultWindow["ofetch"]).raw(url, options);
};
