// Utilities
import { defineStore } from "pinia";

export const useContextStore = defineStore("context", () => {
  /** @type {Ref<OrganizerJSON>} */
  const organizer = ref();
  /** @type {Ref<EventJSON>} */
  const event = ref();
  /** @type {Ref<OrganizationJSON>} */
  const organization = ref();
  /** @type {Ref<EventParticipationJSON>} */
  const event_participation = ref();

  return {
    organizer,
    event,
    organization,
    event_participation,
  };
});
