/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { createRouter as createRouterLegacy, createWebHistory } from "vue-router";
import { setupLayouts } from "virtual:generated-layouts";
import dayjs from "dayjs";
import { AuthAPI } from "@/utils/generated/api-public";
import { routes } from "vue-router/auto-routes";

/**
 *
 * @returns {import("vue-router").Router}
 */
export function createRouter() {
  const router = createRouterLegacy({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: setupLayouts(routes),
  });

  router.beforeEach(async (to, from, next) => {
    const { authenticate, authenticate_by_cookie } = useAuthStore();
    let me = await authenticate();
    if (isPublicPath(to)) {
      if (!me && allowFundraisingAuth(to)) {
        me = await authenticate_by_cookie();
      }
      return next();
    } else if (me) {
      if (allowInactiveUsers(to)) {
        return next();
      } else if (!me.is_activated && !me.active) {
        return next({
          path: "/sign-up",
          query: {
            email: me.email,
            ...to.query,
            ...to.params,
            redirectUrl: to.fullPath,
          },
        });
      } else if (dayjs().diff(me?.last_login, "month") > 6) {
        return next({
          path: "/reset-password",
          query: {
            email: me.email,
            ...to.query,
            ...to.params,
            redirectUrl: to.fullPath,
          },
        });
      } else {
        return next();
      }
    } else if (isProtectedByCode(to)) {
      if (to.query.organizationId && to.query.email) {
        const auth = await AuthAPI.authByOrgIdAndEmail(to.query);
        if (auth) {
          await authenticate(auth);
          return next();
        }
      } else if (to.query.organizerId && to.query.email) {
        const auth = AuthAPI.authByOrganizerIdAndEmail(to.query);
        if (auth) {
          await authenticate(auth);
          return next();
        }
      } else if (to.query.user_role_id && to.query.email) {
        const auth = await AuthAPI.authByIdRoleAndEmail(to.query);
        if (auth) {
          await authenticate(auth);
          return next();
        }
      }
      return next({
        path: "/verification-by-code",
        query: { ...to.query, ...to.params, redirectUrl: to.fullPath },
      });
    } else if (allowFundraisingAuth(to)) {
      const me = await authenticate_by_cookie();
      if (me) {
        return next();
      } else {
        const orgId = to.params["organizationId"] || to.query["organizationId"];
        if (preferFundraisingAuth(to) || (orgId && (await AuthAPI.checkOrganizationId(orgId))?.type === "CHARITY")) {
          location.href = `${useSettingsStore().settings.fundraisingSSOBaseUrl}/login?redirectUrl=${encodeURIComponent(location.href)}`;
        } else {
          return next({
            path: "/login",
            query: { ...to.query, ...to.params, redirectUrl: to.fullPath },
          });
        }
      }
    } else {
      return next({
        path: "/login",
        query: { ...to.query, ...to.params, redirectUrl: to.fullPath },
      });
    }
  });
  // @ts-ignore
  return router;
}

const isPublicPath = (to) => {
  for (let i = to.matched.length - 1; i >= 0; i--) {
    if (to.matched[i].meta.public !== undefined) {
      return to.matched[i].meta.public;
    }
  }
  return null;
};

const isProtectedByCode = (to) => {
  for (let i = to.matched.length - 1; i >= 0; i--) {
    if (to.matched[i].meta.protected_by_code !== undefined) {
      return to.matched[i].meta.protected_by_code;
    }
  }
  return null;
};

const allowInactiveUsers = (to) => {
  for (let i = to.matched.length - 1; i >= 0; i--) {
    if (to.matched[i].meta.allow_inactive_users !== undefined) {
      return to.matched[i].meta.allow_inactive_users;
    }
  }
  return null;
};

const allowFundraisingAuth = (to) => {
  for (let i = to.matched.length - 1; i >= 0; i--) {
    if (to.matched[i].meta.allow_fundraising_auth !== undefined) {
      return to.matched[i].meta.allow_fundraising_auth;
    }
  }
  return null;
};

const preferFundraisingAuth = (to) => {
  for (let i = to.matched.length - 1; i >= 0; i--) {
    if (to.matched[i].meta.prefer_fundraising_auth !== undefined) {
      return to.matched[i].meta.prefer_fundraising_auth;
    }
  }
  return null;
};
