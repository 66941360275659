/* eslint-disable no-unused-vars */
import { createAPIHandler, useOFetchRaw } from "@/plugins/ofetch";

const _AuthAPI = {
  /** @type {FetchAPI<import("../../../../backend/api/public/auth.js").login>}  */
  login: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/login`,
      { method: "POST", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/auth.js").resetPassword>}  */
  resetPassword: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/reset-password`,
      { method: "PATCH", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/auth.js").signUp>}  */
  signUp: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/sign-up`,
      { method: "POST", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/auth.js").authByOrgIdAndEmail>}  */
  authByOrgIdAndEmail: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/auth-by-org-id-and-email`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/auth.js").authByOrganizerIdAndEmail>}  */
  authByOrganizerIdAndEmail: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/auth-by-organizer-id-and-email`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/auth.js").authByIdRoleAndEmail>}  */
  authByIdRoleAndEmail: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/auth-by-id-role-and-email`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/auth.js").checkOrganizationId>}  */
  checkOrganizationId: createAPIHandler(
    ([organizationId, query = undefined]) => [
      `/n/api/public/check-org-id/${organizationId}`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/auth.js").checkOrganizationFundraisingTeamId>}  */
  checkOrganizationFundraisingTeamId: createAPIHandler(
    ([fundraisingTeamId, query = undefined]) => [
      `/n/api/public/check-org-fundraising-team-id/${fundraisingTeamId}`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/auth.js").checkVerificationCode>}  */
  checkVerificationCode: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/check-verification-code`,
      { method: "POST", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/auth.js").sendVerificationCode>}  */
  sendVerificationCode: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/send-verification-code`,
      { method: "POST", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/auth.js").updatePersonalSettings>}  */
  updatePersonalSettings: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/personal-settings`,
      { method: "PATCH", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/auth.js").logout>}  */
  logout: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/logout`,
      { method: "POST", body, query }
    ]
  ),
};
export { _AuthAPI as AuthAPI };
const _EventParticipationsAPI = {
  /** @type {FetchAPI<import("../../../../backend/api/public/event-participations.js").getEventParticipationByEventAndOrganization>}  */
  getEventParticipationByEventAndOrganization: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/event-participations/search_by_event_and_organization`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/event-participations.js").getFundraisingEventParticipationByEventAndOrganization>}  */
  getFundraisingEventParticipationByEventAndOrganization: createAPIHandler(
    ([organization_id, event_id, query = undefined]) => [
      `/n/api/public/fundraising/${organization_id}/${event_id}/event-participations`,
      { method: "GET", query }
    ]
  ),
};
export { _EventParticipationsAPI as EventParticipationsAPI };
const _EventsAPI = {
  /** @type {FetchAPI<import("../../../../backend/api/public/events.js").getEvent>}  */
  getEvent: createAPIHandler(
    ([event_id, query = undefined]) => [
      `/n/api/public/events/${event_id}`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/events.js").getEventByEventIdOrFundraisingEventId>}  */
  getEventByEventIdOrFundraisingEventId: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/events/find-by-event-id-or-fundraising-event-id`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/events.js").getSalesCategories>}  */
  getSalesCategories: createAPIHandler(
    ([event_id, query = undefined]) => [
      `/n/api/public/events/${event_id}/sales-categories`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/events.js").getEventPaymentMethods>}  */
  getEventPaymentMethods: createAPIHandler(
    ([event_id, query = undefined]) => [
      `/n/api/public/events/${event_id}/payment-methods`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/events.js").getEventEventTickets>}  */
  getEventEventTickets: createAPIHandler(
    ([event_id, query = undefined]) => [
      `/n/api/public/events/${event_id}/event-tickets`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/events.js").findEventDiscountCode>}  */
  findEventDiscountCode: createAPIHandler(
    ([event_id, query = undefined]) => [
      `/n/api/public/events/${event_id}/discount-codes`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/events.js").findSalesDiscountCode>}  */
  findSalesDiscountCode: createAPIHandler(
    ([event_id, body = undefined, query = undefined]) => [
      `/n/api/public/events/${event_id}/sales-discount-codes`,
      { method: "POST", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/events.js").updateRegistration>}  */
  updateRegistration: createAPIHandler(
    ([event_id, registration_id, order_id, body = undefined, query = undefined]) => [
      `/n/api/public/events/${event_id}/registration/${registration_id}/${order_id}`,
      { method: "PATCH", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/events.js").shareTicket>}  */
  shareTicket: createAPIHandler(
    ([event_id, registration_id, register_order_id, body = undefined, query = undefined]) => [
      `/n/api/public/events/${event_id}/registration/${registration_id}/share-ticket/${register_order_id}`,
      { method: "POST", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/events.js").getTaxReceiptStatus>}  */
  getTaxReceiptStatus: createAPIHandler(
    ([event_id, order_id, registration_id, query = undefined]) => [
      `/n/api/public/events/${event_id}/registrations/${order_id}/${registration_id}/tax-receipt-status`,
      { method: "GET", query }
    ]
  ),
  /** @type {DownloadAPI<import("../../../../backend/api/public/events.js").getTaxReceipt>}  */
  getTaxReceipt: async function(event_id, order_id, registration_id, query = undefined) {
    const response = await useOFetchRaw(`/n/api/public/events/${event_id}/registrations/${order_id}/${registration_id}/tax-receipt`, { responseType: "blob", query });
    const href = URL.createObjectURL(response["_data"]);
    const a = document.createElement("a");
    a.href = href;
    a.download = ((response["headers"].get("content-disposition") || "").match(/filename="?(.+)"?/) || [])[1] || "tax-receipt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(href);
    return;
  },
};
export { _EventsAPI as EventsAPI };
const _FundraisingAPI = {
  /** @type {FetchAPI<import("../../../../backend/api/public/fundraising.js").getFundraisingCategories>}  */
  getFundraisingCategories: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/fundraising/categories`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/fundraising.js").getFundraisingCharities>}  */
  getFundraisingCharities: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/fundraising/charities`,
      { method: "GET", query }
    ]
  ),
};
export { _FundraisingAPI as FundraisingAPI };
const _MeAPI = {
  /** @type {FetchAPI<import("../../../../backend/api/public/me.js").getMe>}  */
  getMe: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/me`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/me.js").updateMe>}  */
  updateMe: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/me`,
      { method: "PATCH", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/me.js").getMeByCookie>}  */
  getMeByCookie: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/me-by-cookie`,
      { method: "GET", query }
    ]
  ),
};
export { _MeAPI as MeAPI };
const _OrganizationsAPI = {
  /** @type {FetchAPI<import("../../../../backend/api/public/organizations.js").getOrganization>}  */
  getOrganization: createAPIHandler(
    ([id, query = undefined]) => [
      `/n/api/public/organizations/${id}`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/organizations.js").createOrganization>}  */
  createOrganization: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/organizations`,
      { method: "POST", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/organizations.js").createOrganizationFromFundraising>}  */
  createOrganizationFromFundraising: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/organizations-from-fundraising`,
      { method: "POST", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/organizations.js").getSalesOrderId>}  */
  getSalesOrderId: createAPIHandler(
    ([organization_id, event_id, sales_order_id, query = undefined]) => [
      `/n/api/public/organizations/${organization_id}/event/${event_id}/sales-orders/${sales_order_id}`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/organizations.js").createOrganizationNotification>}  */
  createOrganizationNotification: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/new-organization-notification`,
      { method: "POST", body, query }
    ]
  ),
};
export { _OrganizationsAPI as OrganizationsAPI };
const _PaymentAPI = {
  /** @type {FetchAPI<import("../../../../backend/api/public/payment.js").handlePaymentWebhooks>}  */
  handlePaymentWebhooks: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/payment/webhooks`,
      { method: "POST", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/payment.js").getPaymentReturn>}  */
  getPaymentReturn: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/payment/return`,
      { method: "GET", query }
    ]
  ),
};
export { _PaymentAPI as PaymentAPI };
const _RegisterAPI = {
  /** @type {FetchAPI<import("../../../../backend/api/public/register.js").createRegisterOrder>}  */
  createRegisterOrder: createAPIHandler(
    ([eventId, body = undefined, query = undefined]) => [
      `/n/api/public/events/${eventId}/register`,
      { method: "POST", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/register.js").payRegisterOrder>}  */
  payRegisterOrder: createAPIHandler(
    ([event_id, order_id, body = undefined, query = undefined]) => [
      `/n/api/public/events/${event_id}/register/${order_id}/pay`,
      { method: "POST", body, query }
    ]
  ),
};
export { _RegisterAPI as RegisterAPI };
const _RegistrationsAPI = {
  /** @type {FetchAPI<import("../../../../backend/api/public/registrations.js").getRegistration>}  */
  getRegistration: createAPIHandler(
    ([unique_id, query = undefined]) => [
      `/n/api/public/registrations/${unique_id}`,
      { method: "GET", query }
    ]
  ),
  /** @type {DownloadAPI<import("../../../../backend/api/public/registrations.js").getRegistrationQr>}  */
  getRegistrationQr: async function(unique_id, query = undefined) {
    const response = await useOFetchRaw(`/n/api/public/registrations/${unique_id}/qr`, { responseType: "blob", query });
    const href = URL.createObjectURL(response["_data"]);
    const a = document.createElement("a");
    a.href = href;
    a.download = ((response["headers"].get("content-disposition") || "").match(/filename="?(.+)"?/) || [])[1] || "qr";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(href);
    return;
  },
  /** @type {FetchAPI<import("../../../../backend/api/public/registrations.js").getRegisterOrder>}  */
  getRegisterOrder: createAPIHandler(
    ([order_id, unique_id, query = undefined]) => [
      `/n/api/public/registrations/register-orders/${order_id}/${unique_id}`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/registrations.js").createRegistrationRecovery>}  */
  createRegistrationRecovery: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/registrations/registration-recovery`,
      { method: "POST", body, query }
    ]
  ),
  /** @type {DownloadAPI<import("../../../../backend/api/public/registrations.js").downloadTicket>}  */
  downloadTicket: async function(unique_id, query = undefined) {
    const response = await useOFetchRaw(`/n/api/public/registrations/${unique_id}/download-ticket`, { responseType: "blob", query });
    const href = URL.createObjectURL(response["_data"]);
    const a = document.createElement("a");
    a.href = href;
    a.download = ((response["headers"].get("content-disposition") || "").match(/filename="?(.+)"?/) || [])[1] || "download-ticket";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(href);
    return;
  },
  /** @type {DownloadAPI<import("../../../../backend/api/public/registrations.js").downloadPaymentReceipt>}  */
  downloadPaymentReceipt: async function(order_id, unique_id, query = undefined) {
    const response = await useOFetchRaw(`/n/api/public/registrations/${order_id}/${unique_id}/download-payment-receipt`, { responseType: "blob", query });
    const href = URL.createObjectURL(response["_data"]);
    const a = document.createElement("a");
    a.href = href;
    a.download = ((response["headers"].get("content-disposition") || "").match(/filename="?(.+)"?/) || [])[1] || "download-payment-receipt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(href);
    return;
  },
};
export { _RegistrationsAPI as RegistrationsAPI };
const _SettingsAPI = {
  /** @type {FetchAPI<import("../../../../backend/api/public/settings.js").getSettings>}  */
  getSettings: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/settings`,
      { method: "GET", query }
    ]
  ),
};
export { _SettingsAPI as SettingsAPI };
const _SmtpAPI = {
  /** @type {FetchAPI<import("../../../../backend/api/public/smtp.js").handleSMTPWebhooks>}  */
  handleSMTPWebhooks: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/smtp/webhooks`,
      { method: "POST", body, query }
    ]
  ),
};
export { _SmtpAPI as SmtpAPI };
const _UsersAPI = {
  /** @type {FetchAPI<import("../../../../backend/api/public/users.js").getUser>}  */
  getUser: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/users/search`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/users.js").getEmailPreferences>}  */
  getEmailPreferences: createAPIHandler(
    ([query = undefined]) => [
      `/n/api/public/users/email-preferences`,
      { method: "GET", query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/users.js").updateEmailPreferences>}  */
  updateEmailPreferences: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/users/email-preferences`,
      { method: "PATCH", body, query }
    ]
  ),
  /** @type {FetchAPI<import("../../../../backend/api/public/users.js").createContactSupport>}  */
  createContactSupport: createAPIHandler(
    ([body = undefined, query = undefined]) => [
      `/n/api/public/users/contact-support`,
      { method: "POST", body, query }
    ]
  ),
};
export { _UsersAPI as UsersAPI };
