/**
 * @type {Record<string, ValidatorDefinition>}
 */
export const rules = {
  required: (v) => !!v && v.length !== 0,
  requiredIf: (v, condition) => {
    return condition ? !!v && v.length !== 0 : true;
  },
  maxLength: (v, max) => !v || v.length <= max,
  minLength: (v, min) => !v || v.length >= min,
  min: (v, min) => v >= min || !v,
  maxNumericValue: (v, max) => +v < max || !v,
  isUrl: (v) => !v || /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(v),
  nonNegative: (v) => !v || parseFloat(("" + v).replace(".", ",")) >= 0,
  number: (v) => !v || !Number.isNaN(Number(v)),
  isString: (v) => !v || typeof v !== "string",
  isNumber: (v) => !v || typeof v !== "number",
  isDate: (v) => !v || !(v instanceof Date),
  passwordMatch: (v, confirm) => v === confirm,
  isNotificationsFormCorrect: (v, conditions) => conditions.includes(true),
  nonDecimal: (v) => !v || !(v % 1),
  zipCode: (v, country) => !v || country != "FR" || /^[0-9]{5}$/.test(v.trim().replace(/\s/g, "")),
  isEmail: (v) => !v || /^[a-zA-Z0-9+_-]+(?:\.[a-zA-Z0-9+_-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(v),
  isValidUrl: (v) => {
    try {
      return !v || Boolean(new URL(v));
    } catch (e) {
      console.error(e);
      return !v || false;
    }
  },
  isHexColor: (v) => !v || /^#?[0-9a-f]{3}([0-9a-f]{3}([0-9a-f]{2})?)?$/i.test(v),
  isArrayLength: (v, length) => {
    const arrLength = v?.split(",")?.length;
    return arrLength === length;
  },
};

/**
 * @type {Record<keyof typeof rules, (...options: any[]) => ValidationRule>}
 */
export let $v;

export const useValidation = () => $v;

export const createValidator = function (i18n) {
  return {
    install(app) {
      $v = rulify(rules, i18n);
      app.config.globalProperties.$v = $v;
    },
  };
};

/**
 * @template {typeof rules} T
 * @param {T} rules
 * @returns {Record<keyof T, (...options: any[]) => ValidationRule>}
 */
function rulify(rules, i18n) {
  const $t = i18n.global.t;
  // @ts-ignore
  return Object.fromEntries(
    Object.entries(rules).map(([key, validator]) => {
      return [
        key,
        function (...args) {
          return function (value) {
            let valid = false;
            try {
              valid = validator.apply(this, [value, ...args]);
            } catch (err) {
              console.error(err);
              // if value is empty and rule not handling this case, return true
              if (!value) return true;
            }
            return (
              valid || $t(`errors.validator.${key}`, args.length === 1 && typeof args[0] === "object" ? args[0] : args)
            );
          };
        },
      ];
    })
  );
}
