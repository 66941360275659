import { nanoid } from "nanoid";
import { defineStore } from "pinia";
import { ofetch } from "ofetch";
import { SettingsAPI } from "@/utils/generated/api-public";

export const useSettingsStore = defineStore("settings", () => {
  const sid = ref(nanoid(5));
  const settings = SettingsAPI.getSettings.ref();

  async function loadSettings() {
    settings.value = await ofetch("/n/api/public/settings", { headers: { Sid: sid.value } });
    return settings.value;
  }

  return {
    settings,
    loadSettings,
    sid,
  };
});
